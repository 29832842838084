import React, {
  useEffect, useState, useRef, Fragment
} from "react";
import { Col, Row, Grid } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";
import * as Service from "../core/Service";
import { COLORS, FONTS } from "../theme";
import step from "../data/constant";

const { useBreakpoint } = Grid;

const Step = ({ currentStep }) => {
  const screens = useBreakpoint();
  const { xs, sm, md } = screens;

  if (xs || (sm && !md)) {
    return (
      <Row justify="center">
        {_.map(step, (item, index) => {
          let color = COLORS.text.caption;
          let backgroundColor = "#FFF";
          let displayStr = item.desc;
          if (currentStep >= item.step) {
            color = COLORS.text.primary;
            backgroundColor = COLORS.brand.primary;
            if (currentStep > item.step) {
              // displayStr = "(完成)";
            }
          }

          if (currentStep === item.step) {
            return (
              <Fragment key={index}>
                <Col
                  span={24}
                  style={{
                    ...styles.block,
                    ...FONTS.desktop.h6,
                    backgroundColor,
                    color,
                    width: "100%",
                    maxWidth: 820,
                  }}
                >
                  <Row justify="center">
                    <Col
                      style={{
                        ...FONTS.desktop.h6,
                        color: backgroundColor,
                        padding: "4px 14px",
                        borderRadius: 99,
                        marginBottom: 10,
                        backgroundColor: color,
                      }}
                    >
                      {item.step}
                    </Col>
                  </Row>
                  <div
                    style={{ ...FONTS.desktop.text, fontWeight: "bold", color }}
                  >
                    {displayStr}
                  </div>
                </Col>
                {/* {item.step < step.length
              && (
              <Row justify="center">
                <Col span={5} style={{height: 50, width: 50, backgroundColor}} />
              </Row>
              )} */}
              </Fragment>
            );
          }
        })}
      </Row>
    );
  }

  return (
    <Row align="middle">
      {_.map(step, (item, index) => {
        let color = COLORS.text.caption;
        let backgroundColor = COLORS.brand.primary;
        let displayStr = item.desc;
        let circleColor = color;
        let circleTextColor = backgroundColor;
        if (currentStep >= item.step) {
          color = COLORS.text.primary;
          circleTextColor = "#fff";
          circleColor = "#108ee9";
          backgroundColor = COLORS.brand.primary;
          if (currentStep > item.step) {
            // displayStr = "(完成)";
          }
        }
        return (
          <Fragment key={index}>
            <Col
              flex={5}
              style={{
                ...styles.block,
                ...FONTS.desktop.h6,
                backgroundColor,
                color,
                border: `1px solid ${color}`,
              }}
            >
              <Row justify="center">
                {currentStep > item.step ? (
                  <Col
                    style={{
                      ...FONTS.desktop.h6,
                      padding: "4px 14px",
                      marginBottom: 10,
                    }}
                  >
                    <img
                      alt=""
                      style={{ width: 30, height: 30 }}
                      src="/assets/finish.svg"
                    />
                  </Col>
                ) : (
                  <Col
                    style={{
                      ...FONTS.desktop.h6,
                      color: circleTextColor,
                      padding: "4px 14px",
                      borderRadius: 99,
                      marginBottom: 10,
                      backgroundColor: circleColor,
                    }}
                    // span={6}
                  >
                    {item.step}
                  </Col>
                )}
              </Row>
              <div style={{ ...FONTS.desktop.h6, fontWeight: "bold", color }}>
                {displayStr}
              </div>
            </Col>
            {item.step < step.length && (
              <Col
                flex={1}
                style={{
                  ...styles.connector,
                  backgroundColor: circleTextColor,
                }}
              />
            )}
          </Fragment>
        );
      })}
    </Row>
  );
};

const styles = {
  block: {
    backgroundColor: "#FFF",
    textAlign: "center",
    padding: "12px 0",
    borderRadius: 8,
    maxWidth: 200,
  },
  connector: {
    height: 10,
    backgroundColor: "#FFF",
  },
};

export default Step;
