import React, { useState, useEffect } from "react";
import {
  Layout, Row, Col, Affix, Menu, Button
} from "antd";
import { SearchOutlined, DownOutlined, MenuOutlined } from "@ant-design/icons";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { setDrawerVisible } from "../redux/actions/common";
import TNContent from "./TNContent";
import { COLORS, FONTS } from "../theme";
import * as Service from "../core/Service";

const { Header } = Layout;
const { SubMenu } = Menu;

const AppHeader = () => {
  return <UpperHeader />;
};

const UpperHeader = () => {
  const [dataList, setDataList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    let data = await Service.call("get", "/app_info");
    const phone = _.find(data.returnObj, { type: "phone" }).content;
    const email = _.find(data.returnObj, { type: "email" }).content;
    const address = _.find(data.returnObj, { type: "address" }).content;
    const description = _.find(data.returnObj, { type: "description" }).content;
    const logo = _.find(data.returnObj, { type: "logo" }).content;
    const metaData = {
      phone,
      email,
      address,
      description,
      logo,
    };
    setDataList(metaData);
  };
  return (
    <TNContent fullWidth>
      <Header
        style={{
          backgroundColor: "#fff",
          height: 80,
          padding: "0 5%",
          margin: "auto",
          width: "100%",
        }}
      >
        {/* Desktop */}
        <Col xs={0} sm={0} md={0} lg={24}>
          <Row justify="space-between" align="middle" style={{ height: 80 }}>
            <Col>
              <a href="/">
                <img
                  draggable="false"
                  style={{
                    height: 80,
                    padding: "5px 0",
                    width: "70%",
                    objectFit: "contain",
                  }}
                  alt="company_logo"
                  src={dataList.logo}
                />
              </a>
            </Col>
            <Col span={10}>
              <Row
                justify="space-between"
                align="middle"
                style={{
                  ...FONTS.desktop.text,
                  color: COLORS.text.secondary,
                  fontWeight: "bold",
                }}
              >
                {/* <Col span={2} style={{ display: 'flex', justifyContent: 'center' }}>
                  <SearchOutlined style={{ fontSize: 20 }} />
                </Col> */}

                {/* <Col span={5} style={{ display: 'flex', justifyContent: 'center' }}>
                  <Menu mode="horizontal" style={{ border: 0 }}>
                    <SubMenu
                      title={(
                        <div style={{
                          ...FONTS.desktop.text, color: COLORS.text.secondary, fontWeight: 'bold', display: 'flex', alignItems: 'center'
                        }}
                        >
                          最新消息
                          <DownOutlined style={{fontSize: 10}} />
                        </div>
                  )}
                      className="header-menu"
                      style={{ border: 0, top: -0.2, padding: 0 }}
                    >
                      <Menu.Item>
                        <a href="/">活動</a>
                      </Menu.Item>
                      <Menu.Item>
                        <a href="/">大事件</a>
                      </Menu.Item>
                    </SubMenu>
                  </Menu>
                </Col> */}
                <Col
                  span={3}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a
                    style={{
                      ...FONTS.desktop.h6,
                      color: COLORS.text.primary,
                      fontWeight: "bold",
                    }}
                    href="https://mvcsolution.io"
                  >
                    企業方案
                  </a>
                </Col>
                <Col
                  span={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a
                    style={{
                      ...FONTS.desktop.h6,
                      color: COLORS.text.primary,
                      fontWeight: "bold",
                    }}
                    href="https://mvcsolution.io/about-us"
                  >
                    關於我們
                  </a>
                </Col>
                <Col
                  span={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a
                    style={{
                      ...FONTS.desktop.h6,
                      color: COLORS.text.primary,
                      fontWeight: "bold",
                    }}
                    href="https://mvcsolution.io/contact-us"
                  >
                    聯絡我們
                  </a>
                </Col>
                <Col
                  span={6}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <a href="https://mvcsolution.io/tvp">
                    <Button
                      shape="round"
                      size="large"
                      style={{ backgroundColor: '#87d068' }}
                    >
                      <span
                        style={{ color: COLORS.text.white, fontWeight: "bold" }}
                      >
                        政府資助計劃
                      </span>
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* Desktop */}
        {/* Mobile */}
        <Col xl={0} lg={0} md={24} sm={24} xs={24}>
          <Row justify="space-between" align="middle">
            <Col span={5} style={{ textAlign: "start" }}>
              <MenuOutlined
                style={{ ...styles.icon }}
                onClick={() => dispatch(setDrawerVisible(true))}
              />
            </Col>
            <Col justify="center" align="middle" span={14}>
              <img
                draggable="false"
                style={{ height: 80, objectFit: "cover", width: "100%" }}
                alt="company_logo"
                src="/assets/logo.png"
              />
            </Col>
            <Col span={5} />
          </Row>
        </Col>
        {/* Mobile */}
      </Header>
    </TNContent>
  );
};

const styles = {
  header: {
    backgroundColor: "#FFFFFF",
    zIndex: 999,
    height: "auto",
    padding: 0,
  },
  icon: {
    color: "#bfbfbf",
    marginRight: 10,
    fontSize: 24,
    transform: "translateY(20%)",
  },
};

export default AppHeader;
