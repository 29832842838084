import React from "react";
import { Row, Col, Typography } from "antd";
import _ from "lodash";
import useResponive from "../hooks/useResponive";
// const HtmlToReactParser = require('html-to-react').Parser;
// const htmlToReactParser = new HtmlToReactParser();

const { Title, Paragraph } = Typography;

function FeatureItem(props) {
  const { mode, dataObj } = props;
  const { title, description, image } = dataObj;
  const { isMobile, isTablet, isDesktop } = useResponive();
  return (
    <div style={{ backgroundColor: mode !== "left" ? "#fef8ff" : "#FFF" }}>
      <Row justify="center" style={{ padding: "80px 5%" }}>
        <Col xs={24} md={22}>
          <Row
            style={{ margin: "auto" }}
            align="middle"
            justify="space-between"
            gutter={[0, 24]}
          >
            <Col
              style={{ margin: isTablet || isDesktop ? 0 : "auto" }}
              xs={{ span: 24, order: 0 }}
              md={{
                span: 11,
                order: _.toInteger(mode !== "left"),
              }}
            >
              <div>
                <img
                  src={dataObj.image_url}
                  width="100%"
                  alt=""
                  style={{
                    borderRadius: 16,
                    objectFit: "cover",
                    height: 300,
                  }}
                />
              </div>
            </Col>
            <Col
              style={{ margin: isTablet || isDesktop ? 0 : "auto" }}
              xs={{ span: 24, order: 0 }}
              md={{
                span: 11,
                order: _.toInteger(mode === "left"),
              }}
            >
              <div>
                <Title>{title}</Title>
                <Paragraph style={styles.text}>{description}</Paragraph>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

const styles = {
  text: {
    textAlign: "justify",
    marginTop: 30,
    fontSize: 18,
  },
};

export default FeatureItem;
