const step = [
  {
    step: 1,
    desc: '選擇方案',
    title: '你需要什麼方案?'
  },
  {
    step: 2,
    desc: '選擇服務類別',
    title: '你需要的服務類別是什麼?'
  },
  {
    step: 3,
    desc: '填寫聯絡資料',
    title: '總覽與聯絡資料'
  },
]

export default step
