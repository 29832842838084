import { useEffect } from "react";
import { Grid } from "antd";

const useResponive = () => {
  const screens = Grid.useBreakpoint();
  const {
    xs, sm, md, lg
  } = screens;
  return {
    isMobile: (xs || sm) && !md,
    isTablet: md && !lg,
    isDesktop: lg,
  };
};

export default useResponive;
