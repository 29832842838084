import React from 'react';
import { Layout } from 'antd';
import { COLORS } from '../theme';


const {
  Content
} = Layout;

const TNContent = ({
  children, fullWidth, color, style
}) => {
  return (
    <Content
      style={{
        ...styles.container,
        padding: fullWidth ? 0 : '0px 5%',
        backgroundColor: color || COLORS.background,
        maxWidth: fullWidth ? '100%' : 820,
        ...style
      }}
    >
      {children}
    </Content>
  )
}

const styles = {
  container: {
    width: '100%',
    margin: '0px auto',
  }
}

export default TNContent;
