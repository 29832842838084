// app reducer
export const SET_USER = 'SET_USER';
export const SET_AUTH = 'SET_AUTH';
export const SET_ORIGIN = 'SET_ORIGIN';
export const SET_CONFIG = 'SET_CONFIG';
export const SET_LOADING = 'SET_LOADING';
export const SET_MODAL = 'SET_MODAL';
export const SET_LOGOUTWAY = 'SET_LOGOUTWAY';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const SET_ERROR_CODE = 'SET_ERROR_CODE';
export const SET_SITE_META = 'SET_SITE_META';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_DRAWER = 'SET_DRAWER';
