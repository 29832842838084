import _ from 'lodash';
import * as types from './types';
// import AsyncStorage from '@react-native-community/async-storage';
// import * as Main from '../../core/Main';

export function setConfig(appConfig) {
  return {
    type: types.SET_CONFIG,
    data: appConfig,
  };
}

export function setUser(user) {
  return {
    type: types.SET_USER,
    data: user
  };
}

export function setSiteMeta(site_meta) {
  return {
    type: types.SET_SITE_META,
    data: site_meta
  };
}

export function setAuth(state) {
  return {
    type: types.SET_AUTH,
    data: state
  };
}

export function setModal(state) {
  return {
    type: types.SET_MODAL,
    data: state
  };
}

export function setOrigin(admin) {
  return {
    type: types.SET_ORIGIN,
    data: admin
  };
}

export function setLoading(loading) {
  return {
    type: types.SET_LOADING,
    data: loading
  };
}

export function setLanguage(lang) {
  return {
    type: types.SET_LANGUAGE,
    data: lang
  }
}

export function setErrorMessage(data) {
  return {
    type: types.SET_ERROR_MESSAGE,
    data
  };
}

export function setErrorCode(data) {
  return {
    type: types.SET_ERROR_CODE,
    data
  };
}

export function setDrawerVisible(_visible) {
  return {
    type: types.SET_DRAWER,
    data: _visible
  }
}
