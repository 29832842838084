import React, { useState, useEffect } from 'react';
import { message } from 'antd';
import AppRoute from './AppRoute';

message.config({
  top: '75%',
  maxCount: 1,
});

const AppContainer = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    init();
  }, [])

  const init = async () => {
    setLoading(true);
    // await getConfigList()
    // await getSiteMeta()
    // await getUser()

    setLoading(false);
  }

  return (
    <div>
      <AppRoute />
    </div>
  )
}

const styles = {
  splash: {
    position: 'absolute',
    height: '100vh',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  fadeOut: {
    opacity: 0,
    transition: 'opacity 1s ease-out'
  },
  main: {
    opacity: 0
  },
  fadeIn: {
    opacity: 1,
    transition: 'opacity 1s ease-in'
  }
}

export default AppContainer;
