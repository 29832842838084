import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';

class PubliceRoute extends React.PureComponent {
  render() {
    const {
      component: Component, restricted, app, ...rest
    } = this.props;

    if (app.auth === null) {
      const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
      return (
        <div style={{position: 'absolute', top: '50%', left: '50%'}}>
          <Spin indicator={antIcon} />
        </div>
      )
    }

    return (
      <Route
        {...rest}
        render={(props) => (
          // (app.auth && restricted )?
          // (app.admin.admin_id ? <Redirect to="/admin/home" />: <Redirect to="/company/home" />)
          // :
          <Component />
        )}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PubliceRoute);
