import Immutable from 'seamless-immutable';
import * as types from '../actions/types';

const initialState = Immutable({
  auth: false,
  config: {
    STATIC_SERVER_URL: null,
  },
  loading: false,
  user: {},
  site_meta: {},
  origin: '',
  modal: {
    visible: false,
    title: '',
    content: '',
    className: ''
  },
  errorMessage: "",
  errorCode: null,
  language: 'en',
  drawerVisible: false,
});

const appReducer = {
  app: (state = initialState, action) => {
    switch (action.type) {
      case types.SET_CONFIG: {
        return {...state, config: action.data};
      }
      case types.SET_LANGUAGE: {
        return {...state, language: action.data};
      }
      case types.SET_AUTH:
        return {...state, auth: action.data};
      case types.SET_LOADING:
        return {...state, loading: action.data}
      case types.SET_SITE_META:
        return {...state, site_meta: action.data}
      case types.SET_USER:
        if (!action.data || Object.keys(action.data).length === 0) {
          return {
            ...state,
            user: initialState.user,
          };
        }
        return {...state, user: action.data};
      case types.SET_MODAL:
        return {
          ...state,
          modal: {
            ...state.modal,
            ...action.data
          }
        }
      case types.SET_ORIGIN:
        return {...state, origin: action.data}
      case types.SET_ERROR_MESSAGE: {
        return {...state, errorMessage: action.data};
      }
      case types.SET_ERROR_CODE: {
        return {...state, errorCode: action.data};
      }
      case types.SET_DRAWER:
        return {...state, drawerVisible: action.data};
      default:
        return state;
    }
  }
};

export default appReducer;
