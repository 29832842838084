import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Row,
  notification,
  Typography,
  Skeleton,
  Spin,
  Divider,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import Lottie from "lottie-react";
import * as Service from "../core/Service";
import AppLayout from "../components/AppLayout";
import { COLORS, FONTS } from "../theme";
import TNContent from "../components/TNContent";
import Step from "../components/Step";
import FormContent from "../components/FormContent";
import animationData from "../assets/business-lottie.json";
import useResponive from "../hooks/useResponive";
import FeatureItem from "../components/FeatureItem";

const { Text } = Typography;

const featureList = [
  {
    title: "科技卷資助",
    description:
      "已服務超過1000位中小企客戶，政府創科局最多支援每間中小企60萬的支助，令中小企透過科技提高生產力及升級轉型。",
    image_url: "/bg-1.jpg",
  },
  {
    title: "網頁/手機網站開發",
    description:
      "MVC 了解客戶所需，網頁/手機網站除全面功能性及(User-Friendly)友好使用外，更重要是後台管理以及系統可伸延性。簡單操作，功能豐富，設計精美，就是選用MVC網頁/手機網站開發的原因。",
    image_url: "/bg-2.jpg",
  },
  {
    title: "手機應用程式開發",
    description:
      "隨時代不斷進步，手機應用程式已經成為宣傳你的公司商品/服務及品牌的必備工具，我們的專業開發人員，設計團隊和項目管理擁有多年及全方位開發經驗，能滿足到任何Native App還是Hybrid App的開發需求。",
    image_url: "/bg-3.jpg",
  },
];

const Home = () => {
  const { isMobile } = useResponive();
  const [currentStep, setCurrentStep] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const container = useRef();

  useEffect(() => {
    (async function serverAction() {
      await getAllData("/type");
    }());
  }, []);

  const getAllData = async (link) => {
    let { allOption } = await Service.call("get", link);
    if (_.isNull(allOption)) {
      setIsLoading(false);
      return;
    }
    const dataSource = _.orderBy(
      _.filter(allOption, { is_enable: 1 }),
      ["sorting", "ctime"],
      ["asc", "desc"]
    );
    setDataList(dataSource);
    setIsLoading(false);
  };

  return (
    <AppLayout
      color="#fff"
      headerOptions={{
        hide: true,
      }}
    >
      {isLoading ? (
        <Row justify="center" align="middle" style={{ height: "70vh" }}>
          <Spin
            style={{ color: COLORS.brand.primary }}
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
        </Row>
      ) : (
        <TNContent fullWidth>
          <div
            style={{
              // backgroundImage: "url('/quotation-bg.jpg')",
              // backgroundRepeat: 'no-repeat',
              // backgroundSize: 'cover',
              // backgroundPosition: 'top',
              paddingTop: 30,
              paddingBottom: 30,
              backgroundColor: "#fff",
            }}
          >
            <Row justify="center">
              <Col xs={22} md={20}>
                <Row gutter={[48, 24]} justify="space-between" align="middle">
                  <Col xs={24} lg={10}>
                    <div
                      // src="/assets/quotation-bg.jpg"
                      // alt=""
                      style={{
                        backgroundImage: 'url("/assets/quotation-bg.jpg")',
                        backgroundSize: "cover",
                        backgroundPosition: "right",
                        backgroundRepeat: "no-repeat",
                        width: "100%",
                        objectFit: "cover",
                        borderRadius: 8,
                        height: isMobile ? 300 : 500,
                      }}
                    />
                    {/* <Lottie animationData={animationData} loop /> */}
                  </Col>
                  <Col xs={24} md={13}>
                    <Row
                      justify="center"
                      style={{ textAlign: "center", padding: "50px 0" }}
                    >
                      <Col
                        span={24}
                        style={{ ...FONTS.desktop.h4, fontWeight: "bold" }}
                      >
                        {/* 免費獲取報價 */}
                        獲取免費報價或咨詢
                      </Col>
                      {/* <Col span={24} style={{...FONTS.desktop.h6, fontWeight: 'normal'}}>只需3分鐘填寫，讓我們向你提供報價！</Col> */}
                      <Col
                        span={24}
                        style={{ ...FONTS.desktop.h6, fontWeight: "normal" }}
                      >
                        請填寫以下表格，我們的團隊和專家將與您聯係提供免費的報價或查詢。
                      </Col>
                    </Row>
                    <div ref={container} style={{ paddingBottom: 20 }}>
                      <Step currentStep={currentStep} />
                      <FormContent
                        dataList={dataList}
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                      />
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row justify="center">
              <Divider />
              <Col>
                <h2 style={{ textAlign: "center", fontSize: 36, marginTop: 30 }}>企業方案</h2>
              </Col>
              {_.map(featureList, (item, index) => {
                return (
                  <FeatureItem
                    mode={index % 2 === 0 ? "left" : "right"}
                    key={index}
                    dataObj={item}
                  />
                );
              })}
            </Row>
          </div>
        </TNContent>
      )}
    </AppLayout>
  );
};

export default Home;
