import React from 'react';
import {
  BrowserRouter, Redirect, Switch, Route
} from "react-router-dom";
import 'antd/dist/antd.css';
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux';
import { ActionCreators } from './redux/actions';
import Home from './pages/Home';
import PublicRoute from './PublicRoute';
import NotFound from './components/NotFound';

class AppRoute extends React.PureComponent {
  render() {
    // console.log('Pure component AppRoute');
    return (
      <BrowserRouter>
        <Switch>
          <PublicRoute restricted path="/" component={Home} exact />
          <Route restricted path="/404" component={NotFound} exact />
          <Redirect exact from="/*" to="/" />
        </Switch>
      </BrowserRouter>
    )
  }
}

function mapStateToProps(state) {
  return { ...state };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoute);
