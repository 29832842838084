import React from "react";
import { Affix, Button, Layout } from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { SearchOutlined } from "@ant-design/icons";
import { ActionCreators } from "../redux/actions";
import AppHeader from "./AppHeader";
import AppFooter from "./AppFooter";
import AppDrawer from "./AppDrawer";
import TNContent from "./TNContent";
import { COLORS } from "../theme";

class AppLayout extends React.Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      // window.addEventListener('copy', this.handleCopy);
    }
  }

  componentWillUnmount() {
    if (typeof window !== "undefined") {
      // window.removeEventListener('copy', this.handleCopy);
    }
  }

  // handleCopy(event) {
  //   event.preventDefault();
  //   const pagelink = `\n\n文章版權為 Wealth Cafe 所擁有，原文請按： ${document.location.href}`;
  //   event.clipboardData.setData('text', document.getSelection() + pagelink);
  // }

  render() {
    const { children } = this.props;

    return (
      <Layout className="layout">
        <TNContent fullWidth>
          <AppHeader />
          <AppDrawer />
          <div style={{ minHeight: "70vh" }}>{children}</div>
          <Affix style={{ position: "absolute", right: 30 }} offsetBottom={30}>
            <a href="https://mvcsolution.io/tvp">
              <Button
                // shape="round"
                size="large"
                style={{ backgroundColor: "#87d068", borderRadius: 8 }}
              >
                <span style={{ color: COLORS.text.white, fontWeight: "bold" }}>
                  <SearchOutlined style={{ marginRight: 4 }} />
                  政府資助
                </span>
              </Button>
            </a>
          </Affix>
          <AppFooter />
        </TNContent>
      </Layout>
    );
  }
}

function mapStateToProps(state) {
  return {
    app: state.app,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(ActionCreators, dispatch);
}

export default connect(mapStateToProps, mapDispatchToProps)(AppLayout);
