import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Row,
  Select,
  Radio,
  Space,
  Form,
  Input,
  Grid,
} from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useHistory, Link } from "react-router-dom";
import * as Service from "../core/Service";
import { COLORS, FONTS } from "../theme";
import step from "../data/constant";

const { Option } = Select;
const { TextArea } = Input;
const { useBreakpoint } = Grid;

const FormContent = ({ dataList, currentStep, setCurrentStep }) => {
  const stepDetail = _.find(step, { step: currentStep });
  const [first_option, setNeed] = useState({});
  const [second_option, setType] = useState({});
  // const [select, setSelectType] = useState();
  const [third_option, setMoney] = useState({});
  const [form] = Form.useForm();
  const { xs, sm, md } = useBreakpoint();

  const setPage1 = (values) => {
    // let setneed = _.find(dataList, ['type_option_id', values.first_option])
    // setNeed(setneed);
    setCurrentStep(currentStep + 1);
  };

  const setPage2 = (values) => {
    // let settype = _.find(dataList, ['type_option_id', values.second_option])
    // let setmoney = _.find(dataList, ['type_option_id', values.third_option])
    // setType(settype);
    // setMoney(setmoney);
    setCurrentStep(currentStep + 1);
  };

  const setSelect = (values) => {
    setType(_.find(dataList, { type_option_id: values }));
    if (!_.isEmpty(second_option)) {
      form.resetFields(["third_option"]);
    }
  };

  const setPage3 = async (result) => {
    result.first_option = first_option.name;
    result.second_option = second_option.name;
    result.third_option = third_option.name;
    result.price_total = third_option.string_content || third_option.price;
    await Service.call("post", "/order", result);
    setCurrentStep(currentStep + 1);
  };

  const setBack = () => {
    // setNeed();
    // setType();
    // setMoney();
    // form.resetFields();
    window.location = "/";
  };

  const ContentSection = () => {
    switch (currentStep) {
      case 1:
        return (
          <Form form={form} onFinish={setPage1}>
            <Form.Item
              name="first_option"
              rules={[{ required: true, message: " " }]}
            >
              <Radio.Group
                buttonStyle="solid"
                onChange={(e) => {
                  let setneed = _.find(dataList, [
                    "type_option_id",
                    e.target.value,
                  ]);
                  setNeed(setneed);
                }}
              >
                <Row
                  justify="space-between"
                  style={{ marginTop: 32, padding: "0 30px" }}
                >
                  {_.map(dataList, (item) => {
                    if (item.parent_type_option_id === 0) {
                      return (
                        <Col
                          key={item.type_option_id}
                          sm={11}
                          md={11}
                          lg={11}
                          xs={24}
                          style={{
                            padding: 0,
                            textAlign: "center",
                            marginBottom: 10,
                          }}
                        >
                          <Radio.Button
                            className="icon-option"
                            value={item.type_option_id}
                            style={styles.option}
                          >
                            <img
                              alt="icon"
                              src={item.icon_filename}
                              style={{
                                width: "100%",
                                maxHeight: 26,
                                objectFit: "contain",
                              }}
                            />
                            <div
                              style={{
                                color: "#4E647B",
                                paddingTop: "10px",
                                fontFamily: "lato",
                                fontSize: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              {item.name}
                            </div>
                          </Radio.Button>
                        </Col>
                      );
                    }
                  })}
                </Row>
              </Radio.Group>
            </Form.Item>
            <Row justify="end" style={styles.footer}>
              <Button
                disabled={_.isUndefined(first_option)}
                htmlType="submit"
                size="large"
                style={{
                  ...styles.nextButton,
                  border: "2px solid #070c21",
                  backgroundColor: !_.isUndefined(first_option)
                    ? COLORS.brand.primary
                    : undefined,
                  color: !_.isUndefined(first_option)
                    ? COLORS.text.primary
                    : undefined,
                }}
              >
                下一步
              </Button>
            </Row>
          </Form>
        );

      case 2:
        return (
          <div style={{ marginTop: 32, padding: "0 30px" }}>
            <Row style={{ margin: "10px 0", ...styles.titleText }}>
              請選擇方案類別
            </Row>
            <Form form={form} onFinish={setPage2}>
              <Form.Item
                name="second_option"
                rules={[{ required: true, message: " " }]}
              >
                {!_.isEmpty(dataList) && (
                  <Select
                    size="large"
                    placeholder="請選擇方案"
                    style={{ width: "100%", borderRadius: "10px" }}
                    onChange={setSelect}
                  >
                    {_.map(
                      _.filter(dataList, {
                        parent_type_option_id: first_option.type_option_id,
                      }),
                      (item) => (
                        <Option
                          key={item.type_option_id}
                          value={item.type_option_id}
                        >
                          {item.name}
                        </Option>
                      )
                    )}
                  </Select>
                )}
              </Form.Item>
              {!_.isEmpty(second_option) && (
                <>
                  <Row style={{ margin: "10px 0", ...styles.titleText }}>
                    請選擇類型&quot;價錢&quot;
                  </Row>
                  <Form.Item
                    name="third_option"
                    rules={[{ required: true, message: '請選擇類型"價錢"' }]}
                  >
                    <Radio.Group
                      onChange={(e) => {
                        let setmoney = _.find(dataList, [
                          "type_option_id",
                          e.target.value,
                        ]);
                        setMoney(setmoney);
                      }}
                    >
                      <Space direction="vertical">
                        {_.map(
                          _.filter(dataList, {
                            parent_type_option_id: second_option.type_option_id,
                          }),
                          (item) => (
                            <Radio
                              key={item.type_option_id}
                              value={item.type_option_id}
                            >
                              <b>
                                {' '}
                                {item.name}
                                {" "}
                                {_.isEmpty(item.string_content)
                                  ? `$${item.price.toLocaleString()}`
                                  : item.string_content}

                              </b>
                            </Radio>
                          )
                        )}
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </>
              )}
              <div
                style={{
                  margin: "10px 0",
                  ...FONTS.desktop.text,
                  color: COLORS.line,
                }}
              >
                *此服務的參考價格範圍屬大概估算，最終報價會以專才自身的報價考慮因素不同而有一定差距，收費應以專才報價為準。
              </div>
              <Row
                justify="space-between"
                style={{ ...styles.footer, padding: 0, paddingBottom: 20 }}
              >
                <Button
                  size="large"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                    setNeed();
                    setType();
                    setMoney();
                    form.resetFields();
                  }}
                  style={styles.backButton}
                  icon={<LeftOutlined />}
                >
                  返回
                </Button>
                <Button
                  htmlType="submit"
                  size="large"
                  style={{
                    ...styles.nextButton,
                    backgroundColor:
                      !_.isUndefined(second_option)
                      && !_.isUndefined(third_option)
                        ? COLORS.brand.primary
                        : COLORS.border,
                    color:
                      !_.isUndefined(second_option)
                      && !_.isUndefined(third_option)
                        ? COLORS.text.primary
                        : COLORS.text.caption,
                  }}
                >
                  下一步
                </Button>
              </Row>
            </Form>
          </div>
        );

      case 3:
        return (
          <div style={{ marginTop: 32, padding: "0 30px" }}>
            <Form
              className="contact-form"
              form={form}
              onFinish={setPage3}
              layout="vertical"
            >
              <Row gutter={[24, 24]}>
                <Col sm={10} md={10} lg={10} xs={24}>
                  <div
                    style={{
                      margin: "10px 0",
                      ...styles.titleText,
                      paddingBottom: "10px",
                    }}
                  >
                    服務總覽
                  </div>
                  <div>
                    <div
                      style={{
                        paddingBottom: "5px",
                        color: "#777777",
                        fontSize: "14px",
                      }}
                    >
                      服務
                    </div>
                    <div
                      style={{
                        paddingBottom: "20px",
                        color: "#4E647B",
                        fontSize: "16px",
                        fontWeight: 'bold'
                      }}
                    >
                      {first_option.name}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        paddingBottom: "5px",
                        color: "#777777",
                        fontSize: "14px",
                      }}
                    >
                      項目類別
                    </div>
                    <div
                      style={{
                        paddingBottom: "20px",
                        color: "#4E647B",
                        fontSize: "16px",
                        fontWeight: 'bold'
                      }}
                    >
                      {second_option.name}
                    </div>
                  </div>
                  <div>
                    <div
                      style={{
                        paddingBottom: "5px",
                        color: "#777777",
                        fontSize: "14px",
                      }}
                    >
                      初步估價
                    </div>
                    <div style={{ color: "#4E647B", fontSize: "16px", fontWeight: 'bold' }}>
                      {third_option.name}
                      {" "}
                      {_.isEmpty(third_option.string_content)
                        ? `$${third_option.price.toLocaleString()}`
                        : third_option.string_content}
                    </div>
                  </div>
                </Col>
                <Col sm={14} md={14} lg={14} xs={24}>
                  <div style={{ margin: "10px 0", ...styles.titleText }}>
                    提供聯絡資料，快速直接獲得報價
                  </div>
                  <Form.Item
                    name="company_name"
                    label="公司名稱"
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="company_business"
                    label="公司業務"
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name="user_email"
                    label="電郵地址"
                    labelAlign=""
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                  <Row justify="space-between">
                    <Col sm={11} md={11} lg={11} xs={24}>
                      <Form.Item
                        name="user_first_name"
                        label="名字"
                        rules={[
                          {
                            required: true,
                            message: " ",
                          },
                        ]}
                      >
                        <Input
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={11} md={11} lg={11} xs={24}>
                      <Form.Item
                        name="user_last_name"
                        label="姓氏"
                        rules={[
                          {
                            required: true,
                            message: " ",
                          },
                        ]}
                      >
                        <Input
                          style={{
                            borderRadius: "10px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    name="user_mobile"
                    label="電話"
                    rules={[
                      {
                        required: true,
                        message: " ",
                      },
                    ]}
                  >
                    <Input
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    required
                    validateTrigger="onBlur"
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                    ]}
                    // rules={[
                    //   () => ({
                    //     validator(__, value) {
                    //       if (_.isUndefined(value) || value.length < 20) {
                    //         return Promise.reject(new Error(' '))
                    //       }
                    //       return Promise.resolve()
                    //     },
                    //   })
                    // ]}
                    name="order_description"
                    label="留言"
                  >
                    <TextArea
                      rows={4}
                      style={{
                        borderRadius: "10px",
                      }}
                    />
                  </Form.Item>
                  {/* <div style={{marginTop: " -23.5px", color: '#C9C9C9', fontSize: '14px'}}>
                    不少於20個字
                  </div> */}
                </Col>
              </Row>
              <div
                style={{
                  margin: "10px 0",
                  ...FONTS.desktop.text,
                  color: COLORS.line,
                }}
              >
                *此服務的參考價格範圍屬大概估算，最終報價會以專才自身的報價考慮因素不同而有一定差距，收費應以專才報價為準。
              </div>

              <Row
                justify="space-between"
                style={{ ...styles.footer, padding: 0, paddingBottom: 20 }}
              >
                <Button
                  size="large"
                  onClick={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                  style={styles.backButton}
                  icon={<LeftOutlined />}
                >
                  返回
                </Button>
                <Button
                  htmlType="submit"
                  size="large"
                  style={{
                    ...styles.nextButton,
                    backgroundColor:
                      !_.isUndefined(second_option)
                      && !_.isUndefined(third_option)
                        ? COLORS.text.primary
                        : COLORS.border,
                    color:
                      !_.isUndefined(second_option)
                      && !_.isUndefined(third_option)
                        ? COLORS.text.white
                        : COLORS.text.caption,
                  }}
                >
                  提交
                </Button>
              </Row>
            </Form>
          </div>
        );

      case 4:
        return (
          <div
            style={{ marginTop: 32, padding: "0 30px", textAlign: "center" }}
          >
            <div style={{ paddingTop: "32px" }}>
              <img alt="" src="/assets/finish.svg" />
            </div>
            <div style={{ margin: "10px 0", ...styles.titleText }}>
              你已提交資料
            </div>
            <div
              style={{
                paddingBottom: "32px",
                color: "#4E647B",
                fontSize: "14px",
              }}
            >
              我們已收到你的信息，稍後會有專人聯絡
            </div>
            <div style={{ paddingBottom: "32px" }}>
              <Button
                size="large"
                style={styles.finishButton}
                onClick={() => {
                  setBack();
                }}
              >
                返回首頁
              </Button>
            </div>
          </div>
        );

      default:
    }
  };

  // if (!_.isEmpty(stepDetail)) {
  return (
    <div style={styles.container}>
      {currentStep < 4 ? (
        <Row justify="center" align="middle" style={styles.titleSection}>
          <Col>
            <img
              alt="info"
              src="/assets/info.svg"
              style={{
                ...styles.icon,
                width: xs || (sm && !md) ? "80%" : "100%",
              }}
            />
          </Col>

          <Col
            style={{
              ...FONTS.desktop.h4,
              fontSize: xs || (sm && !md) ? 20 : 24,
              color: COLORS.text.white,
              marginLeft: 13,
            }}
          >
            {stepDetail.title}
          </Col>
        </Row>
      ) : null}
      <ContentSection />
      {/* <Row justify={currentStep > 1 ? 'space-between' : 'end'} style={styles.footer}>
        {currentStep > 1 && <Button size="large" onClick={() => setCurrentStep(currentStep - 1)} style={styles.backButton} icon={<LeftOutlined />}>返回</Button>}
        <Button
          size="large"
          style={styles.nextButton}
          onClick={() => {
            setCurrentStep(currentStep + 1)
          }}
        >
          下一步
        </Button>
      </Row> */}
    </div>
  );
  // }

  // return <div />
};

const styles = {
  container: {
    margin: "30px 0",
    borderRadius: 8,
    backgroundColor: COLORS.text.white,
    // border: '1px solid #000',
    boxShadow: "rgba(0, 0, 0, 0.18) 0px 2px 4px",
  },
  titleSection: {
    backgroundColor: "#17192e",
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
    padding: "8px 0",
  },
  icon: {
    width: "100%",
    objectFit: "contain",
  },
  footer: {
    padding: "0 30px 20px",
  },
  backButton: {
    height: 58,
    borderRadius: 8,
    backgroundColor: COLORS.overlay_2,
    borderWidth: 0,
    ...FONTS.desktop.h5,
    fontSize: 18,
    color: COLORS.text.primary,
  },
  nextButton: {
    height: 58,
    borderRadius: 8,
    backgroundColor: COLORS.border,
    borderWidth: 0,
    ...FONTS.desktop.h5,
    fontSize: 18,
    color: COLORS.text.caption,
    border: '2px solid #000'
  },
  finishButton: {
    height: 58,
    borderRadius: 8,
    backgroundColor: COLORS.brand.primary,
    borderWidth: 0,
    ...FONTS.desktop.h5,
    fontSize: 18,
    color: COLORS.text.white,
  },
  titleText: {
    ...FONTS.desktop.h5,
    fontSize: 18,
    color: COLORS.text.primary,
  },
  option: {
    width: "100%",
    height: "100%",
    padding: 10,
    borderWidth: 3,
    borderColor: "transparent",
    borderRadius: 8,
  },
};

export default FormContent;
