const fontFamily = 'Lato'

const FONTS = {
  desktop: {
    h1: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 54,
      lineHeight: '66px',
      letterSpacing: 1,
      color: '#000'
    },
    h2: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 48,
      lineHeight: '70px',
      letterSpacing: 1,
      color: '#000'
    },
    h3: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 32,
      lineHeight: '55px',
      letterSpacing: 1,
      color: '#000'
    },
    h4: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 28,
      lineHeight: '50px',
      letterSpacing: 2,
      color: '#000'
    },
    h5: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 20,
      lineHeight: '34px',
      letterSpacing: 0.75,
      color: '#000'
    },
    h6: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: 16,
      lineHeight: '28px',
      letterSpacing: 0.75,
      color: '#000'
    },
    text: {
      fontFamily,
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      letterSpacing: 0.75,
      color: '#000'
    }
  }

}

const COLORS = {
  brand: {
    primary: '#fff',
    secondary: '#D26E4B'
  },
  text: {
    primary: '#000000',
    secondary: '#fff',
    caption: '#CACACA',
    white: '#FFFFFF',
    menu: '#404040'
  },
  heading: '#070c21',
  content: '#B5E1F5',
  line: '#C9C9C9',
  background: '#fff',
  border: '#F6F6F6',
  success: '#46B450',
  negative: '#B20000',
  warning: '#FFB900',
  overlay: 'rgba(0, 0, 0, 0.54)',
  overlay_2: 'rgba(44, 144, 68, 0.1)'
}


export {
  COLORS,
  FONTS,
}
