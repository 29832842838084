import React, { useState, useEffect } from "react";
import {
  Menu, Row, Col, Drawer
} from "antd";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { setDrawerVisible } from "../redux/actions/common";

const AppDrawer = () => {
  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch();
  let drawerVisible = useSelector((state) => state.app.drawerVisible);

  useEffect(() => {
    setVisible(drawerVisible);
  }, [drawerVisible]);

  return (
    <Drawer
      title={(
        <Row justify="center" align="middle">
          <Col justify="center" align="middle" span={15}>
            <img
              draggable="false"
              style={{
                marginTop: 30,
                width: "100%",
                height: 100,
                objectFit: "cover",
              }}
              src="/assets/logo.png"
              alt="company_logo"
            />
          </Col>
        </Row>
      )}
      width="100%"
      placement="left"
      closable
      onClose={() => {
        dispatch(setDrawerVisible(false));
      }}
      visible={visible}
      key="left"
      bodyStyle={{ padding: 0, paddingBottom: 50, marginTop: 10 }}
      drawerStyle={{ padding: 0 }}
    >
      <Menu
        style={{ width: "100%" }}
        closable
        className="header-menu"
        mode="inline"
      >
        <Menu.Item
          key="1"
          style={{ fontSize: 16 }}
          onClick={() => {
            dispatch(setDrawerVisible(false));
          }}
        >
          <a href="https://mvcsolution.io/about-us">關於我們</a>
        </Menu.Item>
        {/* <Menu.SubMenu
          key="2"
          style={{ paddingLeft: 0 }}
          title={(
            <div size="large" className="hyperlink" style={{ fontSize: 16 }}>
              最新消息
            </div>
          )}
        >
          <Menu.Item
            key="sub1"
            onClick={() => {
              dispatch(setDrawerVisible(false));
            }}
            className="hyperlink"
          >
            <a href="/">活動</a>
          </Menu.Item>
          <Menu.Item
            key="sub2"
            onClick={() => {
              dispatch(setDrawerVisible(false));
            }}
            className="hyperlink"
          >
            <a href="/">大事件</a>
          </Menu.Item>
        </Menu.SubMenu> */}
        <Menu.Item
          key="1"
          style={{ fontSize: 16 }}
          onClick={() => {
            dispatch(setDrawerVisible(false));
          }}
        >
          <a href="https://mvcsolution.io">企業方案</a>
        </Menu.Item>
        <Menu.Item
          key="1"
          style={{ fontSize: 16 }}
          onClick={() => {
            dispatch(setDrawerVisible(false));
          }}
        >
          <a href="https://mvcsolution.io/contact-us">聯絡我們</a>
        </Menu.Item>
      </Menu>
    </Drawer>
  );
};

export default AppDrawer;
