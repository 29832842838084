import React, {useEffect, useState} from 'react';
import {
  Layout,
  Row,
  Col,
  Typography,
} from 'antd';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import TNContent from './TNContent';
import { COLORS, FONTS } from '../theme';
import * as Service from '../core/Service';

const HtmlToReactParser = require('html-to-react').Parser;

const htmlToReactParser = new HtmlToReactParser();

const { Footer } = Layout;

const AppFooter = () => {
  return (
    <TNContent fullWidth color="#fff">
      <MiddleFooter />
    </TNContent>
  );
};

const MiddleFooter = () => {
  const app = useSelector((state) => state.app);

  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    getAllData()
  }, [])

  const getAllData = async () => {
    let data = await Service.call('get', '/app_info');
    const phone = _.find(data.returnObj, { type: 'phone' }).content
    const email = _.find(data.returnObj, { type: 'email' }).content
    const address = _.find(data.returnObj, { type: 'address' }).content
    const description = _.find(data.returnObj, { type: 'description' }).content
    const logo = _.find(data.returnObj, { type: 'logo' }).content
    const metaData = {
      phone,
      email,
      address,
      description,
      logo
    }
    setDataList(metaData);
  }

  const compInfo = () => {
    return (
      <Row>
        <Col span={24}>
          <div
            style={{
              width: '100%',
              maxWidth: 200,
              marginBottom: 40,
            }}
          >
            <img
              draggable="false"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={dataList.logo}
              alt="company_logo"
            />
          </div>
          <Typography.Paragraph style={{...FONTS.desktop.text, textAlign: 'justify'}}>
            {htmlToReactParser.parse(dataList.description)}
          </Typography.Paragraph>
        </Col>
      </Row>
    );
  };

  const CommonLinkList = () => {
    return (
      <>
        <div style={{ marginBottom: 30 }}>
          <span style={FONTS.desktop.h6}>聯絡我們</span>
        </div>
        <span style={styles.footerTextTitle}>電子郵件</span>
        <div style={{ marginBottom: 20, color: COLORS.text.primary }}>
          {_.includes(dataList.email, ';')
            ? _.map(_.split(dataList.email, ';'), (item, index) => <div key={index}>{item}</div>)
            : <div>{dataList.email}</div>}
        </div>
        <span style={styles.footerTextTitle}>電話</span>
        <div style={{ marginBottom: 20 }}>
          {_.includes(dataList.phone, ';')
            ? _.map(_.split(dataList.phone, ';'), (item, index) => <div key={index}>{item}</div>)
            : <div>{dataList.phone}</div>}
        </div>
        <span style={styles.footerTextTitle}>地址</span>
        <div style={{ marginBottom: 20 }}>
          {_.includes(dataList.address, ';')
            ? _.map(_.split(dataList.address, ';'), (item, index) => <div key={index}>{item}</div>)
            : <div>{dataList.address}</div>}
        </div>
      </>
    );
  };

  const Title = () => {
    return (
      <Row align="middle" justify="space-between" gutter={[40, 0]}>
        <Col span={8}>
          <div
            style={{
              width: '100%',
              maxWidth: 100,
              marginBottom: 10,
            }}
          >
            <img
              draggable="false"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
              }}
              src={dataList.logo}
              alt="company_logo"
            />
          </div>
        </Col>
        <Col span={14}>
          <div style={{ textAlign: 'start'}}>
            <span style={FONTS.desktop.h5}>聯絡我們</span>
          </div>
        </Col>
      </Row>
    )
  }

  const Content = () => {
    return (
      <Row align="top" justify="space-between" gutter={[40, 0]}>
        <Col span={8} style={{ marginTop: 32}}>
          <Typography.Paragraph style={{...FONTS.desktop.text, textAlign: 'justify'}}>
            {htmlToReactParser.parse(dataList.description)}
          </Typography.Paragraph>
        </Col>
        <Col span={14} style={{ textAlign: 'left', ...FONTS.desktop.text }}>
          <Row justify="">
            <Col span={12}>
              <div style={styles.footerTextTitle}>電子郵件</div>
              <div style={{ marginBottom: 20, color: COLORS.text.primary }}>
                {_.includes(dataList.email, ';')
                  ? _.map(_.split(dataList.email, ';'), (item, index) => <div key={index}>{item}</div>)
                  : <div>{dataList.email}</div>}
              </div>
            </Col>
            <Col span={12}>
              <div style={styles.footerTextTitle}>電話</div>
              <div style={{ marginBottom: 20 }}>
                {_.includes(dataList.phone, ';')
                  ? _.map(_.split(dataList.phone, ';'), (item, index) => <div key={index}>{item}</div>)
                  : <div>{dataList.phone}</div>}
              </div>
            </Col>
            <Col>
              <div style={styles.footerTextTitle}>地址</div>
              <div style={{ marginBottom: 20 }}>
                {_.includes(dataList.address, ';')
                  ? _.map(_.split(dataList.address, ';'), (item, index) => <div key={index}>{item}</div>)
                  : <div>{dataList.address}</div>}
              </div>
            </Col>
          </Row>


        </Col>
      </Row>
    )
  }

  return (
    <TNContent fullWidth color="#fff">
      <Footer style={styles.middleFooter}>
        <Row
          justify="space-around"
          style={{
            paddingLeft: '5%', paddingRight: '5%', maxWidth: 1400, margin: 'auto'
          }}
        >
          {/* Mobile */}
          <Col
            xs={24}
            sm={24}
            md={0}
            lg={0}
            style={{ textAlign: 'left', marginBottom: 20 }}
          >
            {compInfo()}
          </Col>
          <Col xs={24} sm={24} md={0} lg={0} style={{ textAlign: 'left' }}>
            {CommonLinkList()}
          </Col>
          {/* Mobile */}

          {/* Desktop */}
          <Col
            xs={0}
            sm={0}
            md={24}
            lg={24}
          >
            {Title()}
            {Content()}
          </Col>
          {/* Desktop */}
        </Row>
      </Footer>
    </TNContent>
  );
};

const LowerFooter = () => {
  const name = 'testing'

  return (
    <>
      <TNContent color="#323232">
        <Footer style={styles.lowerFooter}>
          <Row justify="center">
            {/* Mobile */}
            <Col xs={24} sm={24} md={0} lg={0} style={{ textAlign: 'center' }}>
              <div style={{ color: '#969696', fontSize: 12, marginBottom: 0 }}>
                {`© ${moment().format('YYYY')} ${name}. All rights reserved.`}
              </div>
            </Col>
            {/* Mobile */}

            {/* Desktop */}
            <Col xs={0} sm={0} md={24} lg={24} style={{ textAlign: 'center' }}>
              <div style={{ color: '#969696', fontSize: 14, marginBottom: 0, }}>
                {`© ${moment().format('YYYY')} ${name}. All rights reserved.`}
              </div>
            </Col>
          </Row>
        </Footer>
      </TNContent>
    </>
  );
};

const styles = {
  text: {
    fontSize: 14,
    color: '#000',
  },
  middleFooter: {
    textAlign: 'center',
    margin: 0,
    padding: '25px 0',
    backgroundColor: '#fff',
  },
  lowerFooter: {
    textAlign: 'center',
    margin: 0,
    backgroundColor: '#323232',
  },
  icon: {
    color: '#FFFFFF',
    marginRight: 50,
    fontSize: 24,
    transform: 'translateY(20%)',
  },
  footerTextTitle: {
    fontWeight: 'bold'
  }
};

export default AppFooter;
